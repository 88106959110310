import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welcome to Microgrid Systems.<br/>
          Our website is coming soon.</p>
        <p>
          Your monitoring solution is available here: <a
          className="App-link"
          href="https://balancemonitoring.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          balancemonitoring.com
        </a>.
        </p>
        <p><em>Thank you for visiting us.</em></p>
      </header>
    </div>
  );
}

export default App;
